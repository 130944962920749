<template>
  <v-row 
    v-if="config && categories"
    no-gutters
    :key="type"
  >
    <v-col
      cols="12"
    >
    </v-col>
    <v-col
      cols="8"
      style="padding-right: 10px;"
    >
      <div style="background-color: white;">
        <draggable-table
          v-if="requestsManager"
          :sourceWorkspaceID="workspaceID"
          :data="requestsManager"
          :onSortItems="handleSortItems"
          :onSortStart="handleSortStart"
          :onGetData="handleGetData"
          :onEdit="handleEdit"
          :onCopyItems="handleCopyItems"
          :onDeleteMulti="handleDeleteItems"
          :categories="categories"
        />
        <!--
        sort
        <v-data-table
          v-if="requestsManager"
          :headers="headers"
          :items="requestsManager"
          hide-default-footer
          class="elevation-0"
          :items-per-page="-1"
        >
          <template v-slot:[`item.ident`]="{ item }">
            <a @click="handleEdit(item.ID)">{{ getTranslation(item.Name, locale) }}</a>
            <a @click="handleEdit(item.ID)" v-if="!getTranslation(item.Name, locale) || getTranslation(item.Name, locale).length ===0">{{ item.ID }}</a>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ getTranslation(item.Description, locale) }}
          </template>
</v-data-table>-->
      </div>
    </v-col>
    <v-col
      cols="4"
    >
        <request-form 
          v-if="type"
          :editedID="selectedID"
          :workspaceID="workspaceID"
          :locale="locale"
          :languages="config.languages"
          :onClose="handleCloseForm"
          :type="type"
          :categories="categories"
        />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import apiDtouch from '@/services/apiDtouch'
import RequestForm from './Form'
import DraggableTable from './DraggableTable'
export default {
  components: {
    RequestForm,
    DraggableTable,
  },
  data: () => ({
    type: null,
    config: null,
    selectedID: null,
    requestsManager: null,
    categories: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    headers () {
      return [
          {
            text: this.$t('dtouch.request.name', this.locale),
            align: 'start',
            value: 'ident',
          },
          {
            text: this.$t('dtouch.request.description', this.locale),
            align: 'start',
            value: 'description',
          },
          {
            text: this.$t('dtouch.request.category', this.locale),
            align: 'start',
            value: 'categoryID',
          },
        ]
    },
  },
  watch: {
    '$route.meta.type' () {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.type = this.$route.meta.type
      await this.handleGetConfig()
      this.handleGetData()
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    async handleGetConfig () {
      this.categories = await new Promise((resolve) => apiDtouch.getItem (`v2/private/workspaces/${this.workspaceID}/${this.type}-categories`)
        .then(response => {
          if (!response) resolve([ { ID: null, Name: '' }])
          else {
            const aux = response.Categories ? JSON.parse(response.Categories) : []
            aux.unshift({ ID: null, Name: ''})
            resolve(aux)
          }
        }))
      console.log(this.type, this.workspaceID)
      apiDtouch.getItem (`v2/private/workspaces/${this.workspaceID}`)
        .then(response => {
          if (!response) return
          this.config = {
            languages: response.Languages ? response.Languages.split(',') : []
          }
        })
    },
    handleGetData () {
      apiDtouch.getAll (`v2/private/workspaces/${this.workspaceID}/${this.type}-manager`)
        .then(response => {
          if (!response) return
          console.log('mana', response)
          this.requestsManager = response.map(item => {
            return item
          })
        })
    },
    handleEdit (v) {
      this.selectedID = v
    },
    handleCloseForm (v) {
      this.selectedID = null
      if (v) this.handleGetData()
    },
    handleSortItems (v) {
      this.requestsManager = v
      if (this.requestsManager && this.requestsManager.length) {
        for (let i = 0; i < this.requestsManager.length; i++) {
          this.requestsManager[i].ORD = i
        }
        // save order
        apiDtouch.put (`v2/private/workspaces/${this.workspaceID}/${this.type}-manager/sort`, this.requestsManager)
          .then(() => {
          })
      }
    },
    handleSortStart () {
    },
    handleCopyItems (v) {
      apiDtouch.put (`v2/private/workspaces/${this.workspaceID}/${this.type}-manager/copy-to-workspace`, v)
        .then(() => {
          alert('Copiado correctamente. Se sustituyen las categor�as pero sin los emails de notificaci�n.')
          this.handleGetData()
        })
    },
    handleDeleteItems (v) {
      apiDtouch.put (`v2/private/workspaces/${this.workspaceID}/${this.type}-manager/delete-multi`, v)
        .then(() => {
          alert('Eliminado correctamente')
          this.handleGetData()
        })
    },
  },
}
</script>

